.page-header {
  /** {
    color:#fff;
  }*/
  .intro {
    padding-left:1em;
    border-left: solid .5em $accent;
  }
  .h1 {
    position:relative;
    display:inline-block;
    &:after {
      content: '';
      height: 6px;
      width: 100px;
      position: absolute;
      bottom: -0.25em;
      background: #f08233;
      left: 0;
    }
  }
  h2, .h2 {
    font-size:16px;
    font-family:$body-font;
  }
}
//center-banner
.negative-widget {
  @include media-breakpoint-up(lg) {
    margin-top: -108px;
  }
}
.page-header.center-banner {
  height: 33vh;
  font-size: 24px;
  color: #333;
  h1, .h1 {
    font-size: 1.5em;
    color: $brand-secondary;
    &:after {
      content: '';
      width: 200px;
      height: 2px;
      background: $accent;
      display: block;
      margin: 20px auto 0px;
    }
    @include media-breakpoint-up(md) {
      font-size: 2.5em;
    }
  }
}
.flex-nav {
  @include media-breakpoint-up(xl) {
    display: flex;
    justify-content: flex-end;
  }
}
.content-page-title {
  @include media-breakpoint-down(sm) {
    font-size: 18px!important;
  }
}

.ecommerce-header {
  padding-top:6vw;
  padding-bottom:15px;
}
