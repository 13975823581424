.inner-page-section {
  padding-top: 75px;
  padding-bottom:60px;
  @include media-breakpoint-up(lg) {
    // padding-top:125px;
  }
}

.home .inner-page-section, .contact-us .inner-page-section  {
  padding-top: 0px;
  padding-bottom:0px;
}

.page-container {
  .inner-page-section {
    padding-top: 0px;
    padding-bottom:0px;
    @include media-breakpoint-up(lg) {
      padding-top: 0px;
    }
  }
}

// Accordion Box

.accordion {
  .card {
    background: transparent;
    border: none;

    .card-header {
      margin-top: 10px;
      .card-title {
        padding: 15px;
      }
      &:after {
        font-family: $heading-font;
        content: "-";
        float: right;
        transition: $transition-med;
        padding: 15px;
      }
      &.collapsed:after {
          content: "+";
          color: $accent;
      }
    }

    .card-body {
      padding: 2.25rem;
    }
  }
}
// General Slider - Basic Styling For All Sliders

.ip-content-slider, .ip-image-slider {
  .carousel-control-prev, .carousel-control-next {
    width: 2rem;

    &:hover {
      width: 3rem;
    }
  }

  .carousel-indicators {
    li {
      transition: $transition-med;
    }
  }
}

// Image Slider

.ip-image-slider {
	.carousel-item {
		background-size:cover;
		background-position: center;
		height: 500px;
		width: 100%;

    .carousel-caption {
      text-align: center;
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
      font-family: $heading-font;
    }
	}
}

// Content Slider

.ip-content-slider {

}

// Image Gallery

.ip-image-gallery {
  &.ip-mason-gallery {
    column-gap: 20px;
    column-count: 2;
    @include media-breakpoint-up(md) {
      column-count: 2;
    }
    @include media-breakpoint-up(lg) {
      column-count: 3;
    }

    .gallery-image {
      margin-bottom: 10px;
      margin-top: 10px;

      .card-img-top {
        border-radius: 0;
        border: 0;
      }
    }
  }

  &.ip-flex-gallery {
    .gallery-image {
      background-size: cover;
      background-position: center;
      height: 300px;
      width: calc(100% - 20px);
      margin: 10px;
      @include media-breakpoint-up(md) {
        height: 200px;
        width: calc(50% - 20px);
      }
      @include media-breakpoint-up(lg) {
        height: 300px;
        width: calc(50% - 20px);
      }
      @include media-breakpoint-up(xl) {
        height: 200px;
        width: calc(33.33% - 20px);
      }
    }
  }

  .gallery-image {
    transition: $transition-med;
    box-shadow: 0 0 10px -8px rgba($black,0.1);
    border-radius: 0;
    border: 0;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 0 15px -8px rgba($black,0.2);
    }
  }
}

// Card Deck
.card-img-top {
	min-height: 250px;
	width: 100%;
	background-position: 50%;
	background-size: cover;
	border-radius: 0px;
}
.card-group {

  /*> .card + .card {
    margin-left: 1% !important;
  } */

  .card {
    /*flex-basis: 98%;*/
    /*margin: 1% !important;*/
    /*@include media-breakpoint-up(md) {
      flex-basis: 48%;
      max-width: 50%;
    }
    @include media-breakpoint-up(lg) {
      flex-basis: 31.3%;
      max-width: 50%;
    }*/
    background: $white;
    background-position: 50%;
    background-size: cover;
    border: 0;
    border-radius: 0;

    .card-img-top {
      min-height: 250px;
      width: 100%;
      background-position: 50%;
      background-size: cover;
			border-radius: 0px;
    }

    .card-body {

      .card-title {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 800;
        @include media-breakpoint-up(lg) {
          font-size: 22px;
        }
      }

    }
    .card-footer {
      padding: 1rem 0;
      border-radius: 0;
      background: transparent;
      border: 0;
    }

    &.light-text {
      background-color: $dark;

      .card-body, .card-body * {
        color: $light;
        //text-shadow: 3px 3px 4px rgba($dark,0.4);
      }

      .card-body input, .card-body select {
        color: $dark !important;
      }

      .card-footer {
        padding: 1rem 0;
        border-radius: 0;
        background: rgba($dark,0);
        border: 0;
        box-shadow: -10px 0 10px -8px rgba($dark,0.5);
      }
    }
  }
}


// Columns

.embed-container {
  box-shadow: 0 0 30px -8px rgba($black,0.25);
}

//////////
// REVIEW SLIDER
//////////

#review-slider {

  .five-stars {
    color: gold;
  }

}

// Customizations


///////////////////////
// COMPANY FEATURE
///////////////////////

.about-feature {
  margin-top: 50px;
}

.about-feature .about-feature-1 {
  background:$brand-primary;
}
.about-feature .about-feature-2 {
  background:$brand-primary-offset;
}
.about-feature .about-feature-3 {
  background:$brand-primary;
}
.about-feature .block {
  color: #fff;
  width: 33.33%;
  padding: 5%;
  float: left;
}

.item-title {
  color: $light;
}

.service-icon.company {
  font-size: 60px;
}
.default-content {
  img {
    border-radius:1em;
  }
  [class*="wp-image"] {
    margin: 2em 0px;
    @include media-breakpoint-down(lg) {
      width:100%;
      height:auto;
    }
  }
}
