// Theme Colors/Branding
$brand-primary: #05446D;
$brand-primary-rgb: rgba($brand-primary,1);
$brand-primary-offset: darken($brand-primary,10%);
$brand-secondary: #F08233;
$dark: #002F54;
$light: #BBD5E5;
$medium: #dee2e6;
$inactive: #8c8c8c;
$gray: #666;
$white: #fff;
$accent: #F08233;
$accent-2: #F08233;
$body-font: #666;
$error: #d81e00;
