body {
  background: white;
  overflow-x: hidden;
  /*background: linear-gradient(rgba(255,255,255,.65),rgba(255,255,255,.65)), url(https://t3.ftcdn.net/jpg/00/66/45/70/240_F_66457050_ovBTGvu9b3d0UT1eNB5jrzhUI1QUy0Tc.jpg);*/
  background:linear-gradient(#BBD5E5 10%, #fff 90%);
  background-attachment: fixed;
  background-size: 100vw 100vh;
  background-position:top!important;
  background-repeat:no-repeat!important;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}


// Safari Fixes

// phone link
a[href^=tel] {
  text-decoration: inherit;
  color: inherit;
}

// Accessibility Plugin Adjustments

#pojo-a11y-toolbar {
  height: 100%;
  top: 0!important;
}

.pojo-a11y-toolbar-toggle {
  bottom: 0!important;
}

.pojo-a11y-toolbar-overlay {
  position: absolute!important;
  bottom: 0!important;
  right: 0!important;
}

/** custom classes */
.opacity-0 {
  opacity:0!important;
}
ul.check-list {
  padding-left:0px;
  li {
    list-style:none;

    i, svg {
      margin-right:.5em;
      color:$accent;
      fill:$accent;
    }
  }
}
.w-xs-100 {
  width:100%!important;
  @include media-breakpoint-up(md) {
    width:auto!important;
  }
}
ul.list-style-none {
  padding-left:0px;
  li {
    list-style:none;
  }
}
.modal {
  background:linear-gradient(45deg,rgba(0, 47, 84,.65),rgba(7, 98, 158,.5));
}
.cp-0 {
  padding-left:0px;
  padding-right:0px;
  .row {
    margin-left:0px;
    margin-right:0px;
  }
}
.cpe-0 {
  padding-right:0px!important;
  .row {
    margin-right:0px;
  }
}
.cps-0 {
  padding-left:0px!important;
  .row {
    margin-left:0px;
  }
}
.border {
  border:solid 1px rgba(0,0,0,0.15)!important;
}
.two-columns {
  @include media-breakpoint-up(md) {
    column-count: 2;
    column-gap: 1em;
  }
}
.gdf-wave {
  width:100%;
  height:100px;
  overflow:hidden;
  z-index:1;
  position:absolute;
  left:0;
  &.wave-top {
    top:-2px;
  }
  &.wave-bottom {
    bottom:-2px;
  }
  // position:absolute;
  // top:0;
  // left:0;
  svg {
    path {
      stroke: none;
      fill: $white;
    }
    width: 100%;
    @include media-breakpoint-down(lg) {
      width:150%;
      margin-left: -25%;
    }
    @include media-breakpoint-down(md) {
      width:200%;
      margin-left: -50%;
    }
    &.wave-transparent {
      path {
        fill:transparent;
      }
    }
    &.wave-primary {
      path {
        fill:$brand-primary;
      }
    }
    &.wave-secondary {
      path {
        fill:$brand-secondary;
      }
    }
    &.wave-accent {
      path {
        fill:$accent;
      }
    }
    &.wave-white {
      path {
        fill:#fff;
      }
    }
    &.wave-light {
      path {
        fill:#f6f9fc;
        //fill:#dbeaf2;
      }
    }
    &.wave-dark {
      path {
        fill:$dark;
      }
    }
  }
}
.icon-circle {
  background: white;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 10px;
  font-size: 30px !important;
  position: absolute;
  border: 6px solid #F08233;
  top: -30px;
  margin-left: auto;
  margin-right: auto;
  left: calc(50% - 42px);
  height: 75px;
  width: 75px;
  font-size: 30px;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
  svg, i {
    color:$accent;
    fill:$accent;
  }
}
.sun-right {
  position:relative;
  &:before {
    content:'';
    //animation: growSun 5s linear infinite;
    background:linear-gradient(90deg,rgba(255, 255, 202, .1), rgba(255, 255, 202, 1));
    height:100%;
    position:absolute;
    top:0px;
    right:0px;
    z-index:-1;
    @include media-breakpoint-down(xl) {
      background:linear-gradient(90deg,rgba(255, 255, 202, .1), rgba(255, 255, 202, .65));
    }
  }
  &:after {
    @include media-breakpoint-up(xl) {
      content:'';
      background: radial-gradient(circle at 115%, yellow, $accent 33%, $accent 33%, transparent 33%);
      z-index: -1;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
    }
  }
}
.z-index-1 {
  position:relative;
  z-index:1;
}
.company-building {
  position:relative;
  &:before {
    content: "";
    background: url(../../images/New-Building.jpg);
    background-size:cover;
    background-position:center;
    border-radius: 0px 1em 1em 0px;
    height: 80%;
    left: 0;
    position: absolute;
    top: 0;
    width: 80vw;
    z-index: -1;
    @include media-breakpoint-up(lg) {
      width: 55vw;
    }
    @include media-breakpoint-up(xl) {
      border-radius: 0px 2em 2em 0px;
      width: 66vw;
    }
  }
}
.borders-left {
  border-radius:2em 0px 0px 2em;
}
.borders-right {
  border-radius:0px 2em 2em 0px;
}
.borders-left-xs, .border-radius {
  border-radius:1em;
}
.background-fixed {
  @include media-breakpoint-up(lg) {
    background-attachment: fixed !important;
  }
}

.cursor-hover {
  &:hover {
    cursor:pointer;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.min-height {
  min-height: 66vw;
  @include media-breakpoint-up(xl) {
    min-height: 350px;
  }
}

hr {
  background-color:rgba(0,0,0,0.5);
}

.square {
  padding-top: 100%;
}

.overflow-hidden {
  overflow: hidden!important;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.light-box-shadow {
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
}

.min-height-smaller {
  min-height: 33vh;
  @include media-breakpoint-up(md) {
    min-height: 300px;
  }
}

.min-height-md-down {
  @include media-breakpoint-down(md) {
    min-height: 400px;
  }
}

.pad-top-100 {
  padding-top: 100%!important;
}

.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px $brand-primary;
}

.large-icon * {
  font-size: 2.5em;
  @include media-breakpoint-up(md) {
    font-size: 3em;
  }
  @include media-breakpoint-up(md) {
    font-size: 4em;
  }
}

.grow-bkg {
  transition: all 0.2s ease-in;

  &.grow-this-bkg,
  .grow-this-bkg {
    background-size: 100%!important;
    transition: all 0.2s ease-in;

    &:hover {
      transition: all 0.2s ease-in;
      background-size: 110%!important;
    }
  }
}
.hover-grow {
  transform:scale(1);
  transition: all 0.2s ease-in;

  &:hover {
    transition: all 0.2s ease-in;
    transform:scale(1.05);
  }
}
.hover-shrink {
  transform:scale(1);
  transition: all 0.2s ease-in;

  &:hover {
    transition: all 0.2s ease-in;
    transform:scale(.9);
  }
}
.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px $brand-primary;

  &.small-bio {
    padding: 30px;
    border: solid 5px #fff;
  }
}

.notunder1500 {
  display: none;
}
@media (min-width:1800px) {
  .notunder1500 {
    display: block;
  }
}

.negative-top {
  margin-top: -100px;
}

.negative-top-header {
  margin-top: -47px;
  @include media-breakpoint-up(md) {
    margin-top: -115px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -130px;
  }
}

.clip-top {
  clip-path: polygon(0px 25px, 100% 0, 100% 100%, 0px 100%);
}

.clip-oblique {
  clip-path: polygon(0px 0px, 100% 3%, 100% 97%, 0px 100%);
  @include media-breakpoint-up(lg) {
    clip-path: polygon(0px 0px, 100% 8%, 100% 92%, 0px 100%);
  }
}

.border-bottom-trans-white {
  border-bottom: solid 1px rgba(255,255,255,.1);
}

.box-shadow {
  box-shadow: 0 0 30px rgba(0,0,0,0.1);
}

.box-shadow-small {
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
}
// Images

.img-wrap {
  img {
    max-height: 75px;
    margin: 10px;
  }
}

img {
  max-width: 100%;
}

.img-on-dark-bg {
  filter: brightness(0) invert(1);
}
// Util

.absolute-link {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.absolute-positioner {
  top: 0;
  left: 0;
}

.opacity-soft {
  opacity: 0.97;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-85 {
  opacity: 0.85;
}

.half-opacity {
  opacity: 0.5;
}

.grayscale {
  filter: grayscale(1);
}

.z-index-1 {
  z-index:1;
}

.z-index-100 {
  z-index:100;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, $dark, darken($dark, 10%));
  opacity: 0.85;

  &.light-fade {
    background: linear-gradient(45deg, #000, lighten(#000,10%), #000);
    opacity: 0.6;
  }
}

.sticky {
  @include media-breakpoint-up(md) {
    position: sticky !important;
    top: 200px !important;
  }
}
// Content Images

.img-cover-col {
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
// RESPONSIVE FIXES

.sticky-top {
  @include media-breakpoint-down(sm) {
    position: static;
  }
}

// Move accessibility button to bottom

.pojo-a11y-toolbar-toggle {
  bottom: 0;
}
