.seo {
	h1, h2, h3 {
		margin-top:10px;
		font-size:1rem;
	}
}
@media (max-width:5000px) {
	.seo {
		display:none;
	}
}
h2.h3 {
	margin-bottom:0;
}

h3.h5 {
	margin-bottom:0;
}

.main h1.h4 small:after {
	content: " and surrounding communities";
}

.hidden {
	display: none !important;
}

.bodyimgright,
.container img.bodyimgright,
.plugin-img,
.sidebar img.bodyimgright {
	float: right;
	max-width: 30%;
}
div#secondaryGeos {
	background:$dark;
	padding:1rem;
	text-align:center;

	h4 {
		display:inline-block;
		margin:1rem;
		color:#fff;
	}
}
[data-bs-target="#secondaryGeos"]  {
	display: block;
	width: 100%;
	background: $dark!important;
	color: #fff!important;
	padding-bottom:1rem!important;
	svg {
		display: none!important;
	}
}
