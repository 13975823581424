/** expanding flex cards **/
.expanding-flex-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  transition: all .25s ease-out;
  color: white;

  .credit {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: inherit;
  }

  .options {
    min-width: 100%;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      overflow: hidden;
      width: calc(100% - 100px);
      height: 400px;
      min-height: 33vw;
    }
    @for $i from 1 through 4 {
      @media screen and (max-width:798px - $i*80) {
        min-width: 600px - $i*80;

        .option:nth-child(#{6-$i}) {
          display: none;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      min-width: 100%!important;
    }

    .option {
      position: relative;
      overflow: hidden;
      min-width: 80px;
      margin: 10px;
      background: $accent;
      background-size: auto 120%;
      background-position: center;
      cursor: pointer;
      transition: all .25s ease-out;
      @include media-breakpoint-down(lg){
        width: 47%;
        float: left;
        padding: 0;
        display: inline-block;
        min-height: 50vw!important;

        &:last-child {
          width: calc(100% - 1.5em);
        }
        @include media-breakpoint-down(sm){
          width: 100%;
          min-height:100vw!important;
          margin-top: 1em!important;
        }
      }
      @include media-breakpoint-down(lg) {
        display:block!important;
        transform:scale(1)!important;
        margin-bottom:1rem!important;
        .label {
          height:auto!important;
          flex-direction:column;
          .icon {
            margin-bottom:1rem;
            display:block;
          }
          .main {
            left:0px!important;
          }
        }
      }
      @include media-breakpoint-down(sm) {
        margin: 0px 0px 1em!important;
        width:100%!important;
      }

      &.active {
        flex-grow: 10000;
        border-radius: 2rem;
        background-size: auto 100%;
        transition: all .25s ease-out;
        @include media-breakpoint-down(lg) {
          flex-grow: 10000;
          min-height:25vw;
          transform: scale(1);
        }
        @include media-breakpoint-down(md) {
          min-height:100vw;
        }
        .shadow {
          box-shadow: inset 0 -120px 120px -120px black, inset 0 -120px 120px -100px black;
        }
        .label {
          bottom: 20px;
          left: 20px;
          &:hover {
            transform:translateX(1rem);
          }
          .info>div, .info {
            left: 0px;
            opacity: 1;
          }
        }
        &:active {
          @include media-breakpoint-down(lg) {
            transform:scale(0.9);
          }
        }
      }

      &:not(.active) {
        flex-grow: 1;
        border-radius: 2rem;
        @include media-breakpoint-down(lg) {
          flex-grow: 10000;
          min-height:25vw;
        }
        @include media-breakpoint-down(md) {
          min-height:100vw;
        }
        .shadow {
          bottom: -60px;
          box-shadow: inset 0 -120px 0px -120px black, inset 0 -120px 0px -100px black;
        }

        .label {
          bottom: 10px;
          left: 10px;

          .info>div {
            @include media-breakpoint-up(lg) {
              opacity: 0;
              left:0px;
            }
          }
        }
      }

      .shadow {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        height: 120px;
        transition: .5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
      }

      .label {
        display: flex;
        position: absolute;
        right: 0px;
        height: 60px;
        transition: .5s cubic-bezier(0.05, 0.61, 0.41, 0.95);

        .icon {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          min-width: 60px;
          max-width: 60px;
          height: 60px;
          border-radius: 60px;
          background-color: white;
          color: $accent;
          font-size:1.5em;
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 10px;
          color: white;
          >div {
            position: relative;

            transition: .5s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity .5s ease-out;
          }

          .main {
            font-weight: bold;
            font-size: 1.2rem;
          }

          .sub {
            transition-delay: .1s;
          }
          .text-shadow {
            text-shadow:0px 0px 2em #000;
          }
        }
      }
    }
  }
}
