.offcanvas {
  background-attachment:fixed!important;
  border:0px;

  .navbar-nav {
    .nav-link {
      color:white;
      font-weight:bold;
      padding-top:1rem;
      padding-bottom:1rem;
    }
    .dropdown-menu {
      position:relative!important;
      transform:none!important;
      background:transparent!important;
      border:0;
      border-left: solid 3px $accent;
      border-radius: 0px;

      .nav-item {
        .dropdown-item {
          color:white;
          &:hover {
            background:$dark;
            color:white;
          }
        }
      }
    }
  }
  &.offcanvas-start {
    width:100vw;
  }
}
