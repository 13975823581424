/*
** Custom animations
*/

// CSS only background image fade slider
@keyframes bg-fade-out {
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
}

// Increase AOS Duration && Delay
[data-aos] {
  @for $i from 1 through 200 {
    body[data-aos-duration='#{$i * 50}'] &,
    &[data-aos][data-aos-duration='#{$i * 50}'] {
      transition-duration: #{$i * 50}ms;
    }

    body[data-aos-delay='#{$i * 50}'] &,
    &[data-aos][data-aos-delay='#{$i * 50}'] {
      transition-delay: 0;

      &.aos-animate {
        transition-delay: #{$i * 50}ms;
      }
    }
  }
}

//Sun animation
@keyframes growSun {
  0% {width:0px;}
  50% {width:80%;}
  100% {width:0px;}
}
