/*
** font sizes calculated in perfect fourths using https://type-scale.com/
*/
// set type
$menu-font: 'Montserrat', Helvetica, Arial, sans-serif;
$heading-font: 'Abril Fatface', Helvetica, Arial, sans-serif;
$subheading-font: 'Montserrat', Helvetica, Arial, sans-serif;
$paragraph-font: 'Montserrat', Helvetica, Arial, sans-serif; // set weights
$light-weight: 400;
$normal-weight: 400;
$medium-weight: 4600;
$heavy-weight: 700;
$ultra-weight: 700;

body {
  font-size: 16px;
  line-height: 1.5rem;
  color: $body-font;
  font-family: $paragraph-font;
  @include media-breakpoint-up(md) {
    font-size: 1.125rem; // 18px
  }
}
.script {
  font-family:'Caveat', script!important;
  text-transform: none!important;
}
a {
  color: inherit;
  font-weight: $heavy-weight;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
.bold,
strong {
  font-weight: 700;
}

.sub-heading {
  font-size: 1.25rem;
  font-family: $subheading-font;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font;
  margin-top: 0;
  margin-bottom: 1rem;
  color:$dark;
}

.h1,
h1 {
  font-weight: $heavy-weight;
  font-size: 2rem;
  @include media-breakpoint-up(md) {
    font-size: 2.369rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 3.157rem;
  }
}

.h2,
h2 {
  font-weight: $heavy-weight;
  font-size: 1.777rem;
  @include media-breakpoint-up(lg) {
    font-size: 2.369rem;
  }
}

.h3,
h3 {
  font-weight: $heavy-weight;
  font-size: 1.5rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.777rem;
  }
}

.h4,
h4 {
  font-size: 1rem;
  font-weight: $heavy-weight;
  @include media-breakpoint-up(lg) {
    font-size: 1.333rem;
  }
}

.h5,
h5 {
  font-size: 1rem;
  font-weight: $normal-weight;
  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
}
// Line height

.reduce-line-height {
  line-height: 1 !important;
}
.font-xl {
  .font-xl {
    font-size: 2.5em;
  }
  @include media-breakpoint-up(md) {
    font-size:3.5em;
  }
  @include media-breakpoint-up(lg) {
    font-size:4em;
  }
}
.normal {
  font-size:16px;
  font-family: $paragraph-font;
}
