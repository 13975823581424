.rpb-hero {
  z-index: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .gdf-wave.wave-bottom {
    z-index:0;
  }
  .hero-content {
    position:relative;
    z-index:1;
    flex-grow: 1;
  }
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  // Background Gallery
  .rpb-hero-gallery {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    .rpb-gallery-single {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      z-index: 0;
      opacity: 0;
      animation: bg-fade-out 9s infinite both;
      &:nth-child(2) {
        animation-delay:3s;
      }
      &:nth-child(3) {
        animation-delay:6s;
      }
    }
  }
  // Video Background
  .absolute-slider,
  .hero-bkg-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #101010;

    .slick-list,
    .slick-slide,
    .slick-track {
      width: 100%;
      height: 100%;

      * {
        height: 100%;
      }
    }
  }
  /** embed video styles **/
  .hero-video {
    overflow: hidden !important;
    .autoplay-video {
      top: 50%;
      left: 0px;
      min-height: 100%;
      min-width: 100%;
      position: absolute;
      transform: translateY(-50%) scale(2)!important;
      width: 100%;
    }
    &.video-overlay:before {
      content: '';
      height: 100%;
      width: 100%;
      position: absolute;
      //background: linear-gradient(rgb(5 68 109), rgba(5, 68, 109, 0.33));
      background: linear-gradient(rgba(6, 74, 117), rgba(4, 69, 110, 0));
      z-index: 1;
      top:0px;
      @include media-breakpoint-up(lg) {
        background:linear-gradient(rgba(6, 74, 117,.33), rgba(4, 69, 110, 0));
      }
    }
  }
  /** youtube video styles **/
  .inline-YTPlayer {
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  .hero-bkg-youtube-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// Slider Styles
.rpb-hero-slider {
  .splide__slider {
    .splide__track {
      .splide__list {
        .splide__slide {
          padding-right: 4rem;
          padding-left: 4rem;
        }
      }
    }
  }
}
