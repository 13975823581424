/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

/* Gravity Forms Styling */
.gform_legacy_markup_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_legacy_markup_wrapper textarea, .gfield select, .gform_wrapper.gravity-theme .gform_page_footer input+input {
  font-family: inherit;
  font-size: inherit;
  letter-spacing: normal;
  padding: 15px!important;
  height:60px!important;
  border: solid 1px #ccc!important;
  color:$dark!important;
  border-radius: 10px!important;

  @include media-breakpoint-down(sm) {
    width:100%;
  }
}
.gfield {
  @include media-breakpoint-down(sm) {
    width:100%!important;
  }
  textarea {
    height:300px!important;
  }
}
.white-label label {
  color:#fff;
}
ul#gform_fields_1 {
  display:flex;
  flex-wrap:wrap;
}
.search-form .search-submit,
.gform_footer input[type="submit"], .gform_wrapper.gravity-theme .gform_previous_button.button, .gform_wrapper.gravity-theme .gform_save_link.button, [id*="gform_submit"], .gform_wrapper.gravity-theme .gform_page_footer input[type="button"] {
  border-radius:1em;
  background:$dark;
  color:#fff!important;
  padding:1em 2em!important;
  transition:all .2s ease-out;
  transform:scale(1);
  font-weight:bold!important;

  &:hover {
    transition:all .2s ease-out;
    transform:scale(1.05);
  }
}
input#gform_submit_button_4 {
  background: $accent;
  color: #fff!important;
  padding: 1em 2em!important;
}
.validation_message {
  padding:.5em 1.5em!important;
}
